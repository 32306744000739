<template>
  <div>
    <div class="row">
      <div class="form-group col-4" v-if="false">
        <label>Employee Id</label>
        <fg-input name="employee_id" placeholder="Employee Id" v-model="model.employee_id" readonly></fg-input>
      </div>

      <div class="form-group col-4" v-if="false">
        <label>User Id</label>
        <fg-input name="user_id" placeholder="User Id" v-model="model.user_id" readonly></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Name</label>
        <fg-input
          readonly
          type="text"
          name="name"
          placeholder="Name"
          v-model="model.name"
          v-validate="modelValidations.name"
          :error="getError('name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Phone</label>
        <fg-input
          readonly
          type="text"
          name="phone"
          placeholder="Phone"
          v-model="model.phone"
          v-validate="modelValidations.phone"
          :error="getError('phone')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Alternative Phone</label>
        <fg-input
          readonly
          type="text"
          name="alt_phone"
          placeholder="Alternative Phone"
          v-model="model.alt_phone"
          v-validate="modelValidations.alt_phone"
          :error="getError('alt_phone')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Email</label>
        <fg-input
          readonly
          type="email"
          name="email"
          placeholder="Nil"
          v-model="model.email"
          v-validate="modelValidations.email"
          :error="getError('email')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>DOB</label>
        <fg-input
          readonly
          name="dob"
          placeholder="dob"
          v-model="model.dob"
          v-validate="modelValidations.dob"
          :error="getError('dob')"
        >
          <el-date-picker
            readonly
            name="dob"
            v-model="model.dob"
            type="date"
            placeholder="Nil"
            v-validate="modelValidations.dob"
          ></el-date-picker>
        </fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Father's Name</label>
        <fg-input
          readonly
          type="text"
          name="father_name"
          placeholder="Nil"
          v-model="model.father_name"
          v-validate="modelValidations.father_name"
          :error="getError('father_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Mother's Name</label>
        <fg-input
          readonly
          type="text"
          name="mother_name"
          placeholder="Nil"
          v-model="model.mother_name"
          v-validate="modelValidations.mother_name"
          :error="getError('father_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Spouse's Name</label>
        <fg-input
          readonly
          type="text"
          name="spouse_name"
          placeholder="Nil"
          v-model="model.spouse_name"
          v-validate="modelValidations.spouse_name"
          :error="getError('father_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Maritial Status</label>
        <fg-input readonly type="text" placeholder="Nil" v-model="model.maritial_status"></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Gender</label>
        <fg-input
          readonly
          type="text"
          name="spouse_name"
          placeholder="Nil"
          v-model="model.gender"
          v-validate="modelValidations.spouse_name"
          :error="getError('father_name')"
        ></fg-input>
      </div>
      <!-- <div class="form-group col-4">
        <label for>Religion</label>
        <fg-input readonly type="text" placeholder="Nil" v-model="model.religion"></fg-input>
      </div> -->
    </div>
  </div>
</template>
<script>
import { Upload, DatePicker, Select, Option } from "element-ui";
import { mapGetters } from "vuex";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    ...mapGetters({
      genders: "getGenders",
      maritial_statuses: "getMaritalStatuses",
      religions: "getReligions",
    })
  },
  props: ["model"],
  data() {
    return {
      modelValidations: {
        name: {
          required: false
        },
        dob: {
          required: false
        },
        gender: {
          required: false
        },
        phone: {
          required: false,
          max: 10,
          min: 9
        },
        email: {
          required: false,
          email: false
        },
        father_name: {
          required: false
        },
        mother_name: {
          required: false
        }
      }
    };
  },
  methods: {
    handlePreview(file) {
      this.model.imageUrl = URL.createObjectURL(file.raw);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
