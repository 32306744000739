<template>
  <div class="row justify-content-center">
    <div class="form-group col-3">
      <label for>Present Address</label>
      <fg-input
          readonly
        type="text"
        name="present_address_line"
        placeholder="Present Address"
        v-model="model.present_address_line"
        v-validate="modelValidations.present_address_line"
        :error="getError('present_address_line')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Present Landmark</label>
      <fg-input
          readonly
        type="text"
        name="present_landmark"
        placeholder="Present Landmark"
        v-model="model.present_landmark"
        v-validate="modelValidations.present_landmark"
        :error="getError('present_landmark')"
      ></fg-input>
    </div>

    <div class="form-group col-2">
      <label for>Present State</label>
      <fg-input
          readonly
        name="present_state"
        type="text"
        v-model="model.present_state"
        v-validate="modelValidations.present_state"
        :error="getError('present_state')"
      >
        <el-select
          readonly
          name="present_state"
          v-model="model.present_state"
          filterable
          default-first-option
          placeholder="Choose Present State"
        >
          <el-option
            v-for="item in states"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
      </fg-input>
    </div>

    <div class="form-group col-2">
      <label for>Present Pincode</label>
      <fg-input
          readonly
        type="number"
        name="present_pin"
        placeholder="Present Pincode"
        v-model="model.present_pin"
        v-validate="modelValidations.present_pin"
        :error="getError('present_pin')"
      ></fg-input>
    </div>

    <div class="form-group col-2">
        <label for>Present Country</label>
        <fg-input
          readonly
          name="present_country"
          type="text"
          v-model="model.present_country"
          v-validate="modelValidations.present_country"
          :error="getError('present_country')"
        >
          <el-select
          readonly
            name="present_country"
            v-model="model.present_country"
            filterable
            default-first-option
            placeholder="Choose Present Country"
          >
            <el-option
              v-for="item in countries"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

    <div class="form-group col-3">
      <label for>Permanent Address</label>
      <fg-input
          readonly
        type="text"
        name="permanent_address_line"
        placeholder="Permanent Address"
        v-model="model.permanent_address_line"
        v-validate="modelValidations.permanent_address_line"
        :error="getError('permanent_address_line')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Permanent Landmark</label>
      <fg-input
          readonly
        type="text"
        name="permanent_landmark"
        placeholder="Permanent Landmark"
        v-model="model.permanent_landmark"
        v-validate="modelValidations.permanent_landmark"
        :error="getError('permanent_landmark')"
      ></fg-input>
    </div>

    <div class="form-group col-2">
      <label for>Permanent State</label>
      <fg-input
          readonly
        name="permanent_state"
        type="text"
        v-model="model.permanent_state"
        v-validate="modelValidations.permanent_state"
        :error="getError('permanent_state')"
      >
        <el-select
          readonly
          name="permanent_state"
          v-model="model.permanent_state"
          filterable
          default-first-option
          placeholder="Choose Permanent State"
        >
          <el-option
            v-for="item in states"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
      </fg-input>
    </div>

    <div class="form-group col-2">
      <label for>Permanent Pincode</label>
      <fg-input
          readonly
        type="number"
        name="permanent_pin"
        placeholder="Permanent Pincode"
        v-model="model.permanent_pin"
        v-validate="modelValidations.permanent_pin"
        :error="getError('permanent_pin')"
      ></fg-input>
    </div>

    <div class="form-group col-2">
        <label for>Permanent Country</label>
        <fg-input
          readonly
          name="permanent_country"
          type="text"
          v-model="model.permanent_country"
          v-validate="modelValidations.permanent_country"
          :error="getError('permanent_country')"
        >
          <el-select
          readonly
            name="permanent_country"
            v-model="model.permanent_country"
            filterable
            default-first-option
            placeholder="Choose Permanent Country"
          >
            <el-option
              v-for="item in countries"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

    <div class="form-group col-3">
      <label for>Adhaar Number</label>
      <fg-input
          readonly
        type="text"
        name="adhaar_no"
        placeholder="Adhaar Number"
        v-model="model.adhaar_no"
        v-validate="modelValidations.adhaar_no"
        :error="getError('adhaar_no')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>PAN Number</label>
      <fg-input
          readonly
        type="text"
        name="pan_no"
        placeholder="PAN Number"
        v-model="model.pan_no"
        v-validate="modelValidations.pan_no"
        :error="getError('pan_no')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Bank Name</label>
      <fg-input
          readonly
        type="text"
        name="bank_name"
        placeholder="Bank Name"
        v-model="model.bank_name"
        v-validate="modelValidations.bank_name"
        :error="getError('bank_name')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Bank Branch Name</label>
      <fg-input
          readonly
        type="text"
        name="bank_branch_name"
        placeholder="Bank Branch Name"
        v-model="model.bank_branch_name"
        v-validate="modelValidations.bank_branch_name"
        :error="getError('bank_branch_name')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Bank A/C No</label>
      <fg-input
          readonly
        type="text"
        name="bank_acc_no"
        placeholder="Bank A/C No"
        v-model="model.bank_acc_no"
        v-validate="modelValidations.bank_acc_no"
        :error="getError('bank_acc_no')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Bank IFSC</label>
      <fg-input
          readonly
        type="text"
        name="bank_ifsc_code"
        placeholder="Bank IFSC"
        v-model="model.bank_ifsc_code"
        v-validate="modelValidations.bank_ifsc_code"
        :error="getError('bank_ifsc_code')"
      ></fg-input>
    </div>

  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: ["model"],
  computed: {
    ...mapGetters({
      countries: "getCountries",
      states: "getState"
    })
  },
  data() {
    return {
      countryOptions: ["India", "Other"],
      modelValidations: {
       
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style>
</style>
