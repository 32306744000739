<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10 mr-auto ml-auto">
        <wizard @on-complete="wizardComplete" @on-change="onChange">
          <template slot="header">
            <h3 class="card-title">Employee Details</h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">Step One</template>
            <first-step ref="step1" @on-validated="onStepValidated" :model='model'></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">Step Two</template>
            <second-step ref="step2" @on-validated="onStepValidated" :model='model'></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">Step Three</template>
            <third-step ref="step3" @on-validated="onStepValidated" :model='model'></third-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">Step Four</template>
            <fourth-step ref="step4" @on-validated="wizardComplete" :model='model'></fourth-step>
          </wizard-tab>
        </wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from "./WizardView/FirstStep.vue";
import SecondStep from "./WizardView/SecondStep.vue";
import ThirdStep from "./WizardView/ThirdStep.vue";
import FourthStep from "./WizardView/FourthStep.vue";
import swal from "sweetalert2";

import { Wizard, WizardTab } from "src/components/UIComponents";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      wizardModel: {}
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    Wizard,
    WizardTab
  },

  mounted() {
    this.$store.dispatch("fetchEmployee", this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      model: "getEmployee"
    })
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete(validated, model) {
      this.$router.push(`/employees/list`)
    },
    onChange(prevIndex, nextIndex) {
      console.log("prev", prevIndex, "next", nextIndex);
    }
  }
};
</script>
